import Vue from 'vue'
import Vuex from 'vuex'
import {setAsyncRoutes} from "../router/async"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Token:'1234',
    userInfo:{},
    routes: [],
    // 全部已经格式化过后的树形权限
    allScope:[],
    allTree:[],
    // baseUrl:'http://121.196.103.197/',
    baseUrl:'https://www.lzljsb.cn/',
    pageArray:[10,50,100,200,500,1000]
  },
  mutations: {
    SET_ROUTES(state, routes) {
      // 动态配置路由
      setAsyncRoutes(routes);
      // 为了防止用户刷新页面导致动态创建的路由失效，将其存储在本地中
      // 这里见一个好用的 session 工具，vue-session，可直接安装，使用方式可以在 gitub 上搜索
      sessionStorage.setItem('menu', JSON.stringify(routes));
      // 将路由存储在 store 中
      state.routes = routes;
    },
    SET_USERINFO(state, userdata){
      state.userInfo = userdata;
      state.Token = userdata.token;
    },
    SET_SCOPE(state, all){
      state.allScope = all;
    },
    SET_ALLSCOPE(state, all){
      state.allTree = all;
    },
  },
  actions: {
  },
  modules: {
  }
})
